import React from "react";
import {
  Link,
  useTranslation,
  Trans,
  I18nextContext,
} from "gatsby-plugin-react-i18next";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { Row, Col, Container, Carousel } from "react-bootstrap";
import * as IntroStyles from "../../styles/introduction.module.css";
import useWindowSize from "../useWindowSize";

export default function Introduction(props) {
  const projects = props.projects;
  const { t } = useTranslation();
  const context = React.useContext(I18nextContext);
  const language = context.language;
  const { width } = useWindowSize();

  function GetConstat(language) {
    switch (language) {
      case "fr":
        return require("../../videos/constat/constat_fr.mp4").default;
      case "en":
        return require("../../videos/constat/constat_en.mp4").default;
      case "it":
        return require("../../videos/constat/constat_it.mp4").default;
      case "es":
        return require("../../videos/constat/constat_es.mp4").default;
      case "nl":
        return require("../../videos/constat/constat_nl.mp4").default;
      case "de":
        return require("../../videos/constat/constat_de.mp4").default;
      default:
        return require("../../videos/constat/constat_fr.mp4").default;
    }
  }

  function GetCercleVicieux(language) {
    switch (language) {
      case "fr":
        return require("../../videos/cercle_vicieux/cercle_vicieux_fr.mp4")
          .default;
      case "en":
        return require("../../videos/cercle_vicieux/cercle_vicieux_en.mp4")
          .default;
      case "it":
        return require("../../videos/cercle_vicieux/cercle_vicieux_it.mp4")
          .default;
      case "es":
        return require("../../videos/cercle_vicieux/cercle_vicieux_es.mp4")
          .default;
      case "nl":
        return require("../../videos/cercle_vicieux/cercle_vicieux_nl.mp4")
          .default;
      case "de":
        return require("../../videos/cercle_vicieux/cercle_vicieux_de.mp4")
          .default;
      default:
        return require("../../videos/cercle_vicieux/cercle_vicieux_fr.mp4")
          .default;
    }
  }

  function GetCercleVicieuxUW(language) {
    switch (language) {
      case "fr":
        return require("../../videos/cercle_vicieux_uw/cercle_vicieux_uw_fr.mp4")
          .default;
      case "en":
        return require("../../videos/cercle_vicieux_uw/cercle_vicieux_uw_en.mp4")
          .default;
      case "it":
        return require("../../videos/cercle_vicieux_uw/cercle_vicieux_uw_it.mp4")
          .default;
      case "es":
        return require("../../videos/cercle_vicieux_uw/cercle_vicieux_uw_es.mp4")
          .default;
      case "nl":
        return require("../../videos/cercle_vicieux_uw/cercle_vicieux_uw_nl.mp4")
          .default;
      case "de":
        return require("../../videos/cercle_vicieux_uw/cercle_vicieux_uw_de.mp4")
          .default;
      default:
        return require("../../videos/cercle_vicieux_uw/cercle_vicieux_uw_fr.mp4")
          .default;
    }
  }

  return (
    <Container fluid>
      {/* POURQUOI */}
      <Row className="pb-4 pt-4">
        <Col xs={3} className="align-self-center p-0">
          <div className={IntroStyles.LineBreak}></div>
        </Col>
        <Col xs={6}>
          <h1 className={IntroStyles.Title1}>{t("navbardetail.2")}</h1>
        </Col>
        <Col xs={3} className="align-self-center p-0">
          <div className={IntroStyles.LineBreak}></div>
        </Col>
      </Row>
      <Row>
        <Carousel className={IntroStyles.Carousel} controls={false}>
          <Carousel.Item className={IntroStyles.CarouselItem}>
            <StaticImage
              className={IntroStyles.CarouselImage}
              src={"../../images/slides/SLIDE1.jpg"}
              alt="First slide"
            />
            <Carousel.Caption className={IntroStyles.CarouselCaption}>
              <h2>
                <Trans i18nKey="introduction.2">
                  a<span>b</span>
                </Trans>
              </h2>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item className={IntroStyles.CarouselItem}>
            <StaticImage
              src={"../../images/slides/SLIDE2.jpg"}
              alt="Second slide"
            />
            <Carousel.Caption className={IntroStyles.CarouselCaption}>
              <h2>
                <Trans i18nKey="introduction.2">
                  a<span>b</span>
                </Trans>
              </h2>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </Row>
      {width >= 992 ? (
        <Row className={IntroStyles.Video}>
          <video loop autoPlay muted>
            <source src={GetCercleVicieuxUW(language)} type="video/mp4" />
          </video>
        </Row>
      ) : (
        <Row className={IntroStyles.Video}>
          <video loop autoPlay muted>
            <source src={GetCercleVicieux(language)} type="video/mp4" />
          </video>
        </Row>
      )}
      <Row className={IntroStyles.Video2}>
        <video loop autoPlay muted>
          <source src={GetConstat(language)} type="video/mp4" />
        </video>
      </Row>
      {/* C'EST QUOI */}
      <section className="sec" id="cestquoi">
        {projects.map((project) => (
          <div key={project.id} className="pb-4">
            <Row className="pb-4 pt-4">
              <Col xs={3} className="align-self-center p-0">
                <div className={IntroStyles.LineBreak}></div>
              </Col>
              <Col xs={6} className="justify-content-center">
                <h1 className={IntroStyles.Title1}>
                  {project.frontmatter.title}
                </h1>
              </Col>
              <Col xs={3} className="align-self-center p-0">
                <div className={IntroStyles.LineBreak}></div>
              </Col>
            </Row>
            <Row className="px-4">
              <Col xs={{ span: 12, order: 2 }} lg={{ span: 4, order: 1 }}>
                <GatsbyImage
                  image={getImage(
                    project.frontmatter.thumb.childImageSharp.gatsbyImageData
                  )}
                  alt="gaz"
                />
              </Col>
              <Col xs={{ span: 12, order: 1 }} lg={{ span: 8, order: 2 }}>
                <div
                  className={IntroStyles.HtmlDiv}
                  dangerouslySetInnerHTML={{ __html: project.html }}
                />
                <div className="d-flex flex-row-reverse">
                  <Link className={IntroStyles.Button} to="/introductionplus">
                    {t("ecosysteme.btn")}
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        ))}
      </section>
    </Container>
  );
}
