import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Row, Col, Container } from "react-bootstrap";
import * as BeneStyles from "../../styles/benefices.module.css";

export default function Benefices(props) {
  const benefices = props.benefices;
  const { t } = useTranslation();

  return (
    <Container fluid className={BeneStyles.Container}>
      {/* BENEFICES */}
      <Row className="pb-3 pt-3">
        <Col xs={3} className="align-self-center p-0">
          <div className={BeneStyles.LineBreak}></div>
        </Col>
        <Col xs={6} className="justify-content-center">
          <h1 className={BeneStyles.Title1}>{t("navbar.3")}</h1>
        </Col>
        <Col xs={3} className="align-self-center p-0">
          <div className={BeneStyles.LineBreak}></div>
        </Col>
      </Row>
      <Row className={BeneStyles.GreyRow}>
        {benefices.map((project) => (
          <Col key={project.id} xs={12} lg={6}>
            <Row>
              <div
                className={BeneStyles.HtmlDiv}
                dangerouslySetInnerHTML={{ __html: project.html }}
              />
            </Row>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
