import React, { useState } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Row, Col, Container } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import * as CarteStyles from "../../styles/carteatelier.module.css";

export default function CarteAtelier(props) {
  const ateliers = props.ateliers;

  const { t } = useTranslation();
  const [searchAtelier, setSearchAtelier] = useState("");

  const Atelier = ({
    CP,
    Ville,
    name,
    rue,
    EasyDiag,
    EasyDiagDPF,
    Maxicleaner,
    NettoyageAdmissionFap,
  }) => (
    <Container>
      <Row className={CarteStyles.RowAtelier}>
        <Col xs={12} className="pt-2 pb-2 px-0">
          <a
            href={
              "http://maps.google.com/?q=" +
              name +
              " " +
              rue +
              " " +
              CP +
              " " +
              Ville
            }
          >
            <h2>{name}</h2>
            <p>
              {rue}, {CP} {Ville}
            </p>
          </a>
        </Col>
        <Col xs={12} className="text-center">
          <Row>
            <Col xs={6}>
              <span className={CarteStyles.Span}>EASY</span>DIAG
            </Col>
            <Col xs={6}>
              <span className={CarteStyles.Span}>EASY</span>DIAG-DPF
            </Col>
            <Col xs={6}>
              {EasyDiag === "True" ? (
                <div className={CarteStyles.Green}>
                  <Icon.CheckCircle />
                </div>
              ) : (
                <div className={CarteStyles.Red}>
                  <Icon.SlashCircle />
                </div>
              )}
            </Col>
            <Col xs={6}>
              {EasyDiagDPF === "True" ? (
                <div className={CarteStyles.Green}>
                  <Icon.CheckCircle />
                </div>
              ) : (
                <div className={CarteStyles.Red}>
                  <Icon.SlashCircle />
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <span className={CarteStyles.Span}>MAXI</span>CLEANER
            </Col>
            <Col xs={6}>
              <span className={CarteStyles.Span}>SPHERECLEAN</span>{" "}
              {t("carteAtelier.1")}
            </Col>
            <Col xs={6}>
              {Maxicleaner === "True" ? (
                <div className={CarteStyles.Green}>
                  <Icon.CheckCircle />
                </div>
              ) : (
                <div className={CarteStyles.Red}>
                  <Icon.SlashCircle />
                </div>
              )}
            </Col>
            <Col xs={6}>
              {NettoyageAdmissionFap === "True" ? (
                <div className={CarteStyles.Green}>
                  <Icon.CheckCircle />
                </div>
              ) : (
                <div className={CarteStyles.Red}>
                  <Icon.SlashCircle />
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );

  const AtelierGrid = ({ ateliers }) => (
    <Row className={CarteStyles.AtelierGrid}>
      {ateliers &&
        ateliers
          .filter((atelier) => {
            if (searchAtelier === "") {
              return atelier;
            } else if (atelier.node.name) {
              if (
                atelier.node.name
                  .toLowerCase()
                  .includes(searchAtelier.toLowerCase())
              ) {
                return atelier;
              } else if (atelier.node.rue) {
                if (
                  atelier.node.rue
                    .toLowerCase()
                    .includes(searchAtelier.toLowerCase())
                ) {
                  return atelier;
                } else if (atelier.node.CP) {
                  if (
                    atelier.node.CP.toLowerCase().includes(
                      searchAtelier.toLowerCase()
                    )
                  ) {
                    return atelier;
                  } else if (atelier.node.Ville) {
                    if (
                      atelier.node.Ville.toLowerCase().includes(
                        searchAtelier.toLowerCase()
                      )
                    ) {
                      return atelier;
                    } else {
                      if (atelier.node.EasyDiag === "True") {
                        if (
                          "easydiag"
                            .toLowerCase()
                            .includes(searchAtelier.toLowerCase())
                        ) {
                          return atelier;
                        }
                      }

                      if (atelier.node.EasyDiagDPF === "True") {
                        if (
                          "easydiag-dpf"
                            .toLowerCase()
                            .includes(searchAtelier.toLowerCase())
                        ) {
                          return atelier;
                        }
                      }

                      if (atelier.node.Maxicleaner === "True") {
                        if (
                          "maxicleaner"
                            .toLowerCase()
                            .includes(searchAtelier.toLowerCase())
                        ) {
                          return atelier;
                        }
                      }

                      if (atelier.node.NettoyageAdmissionFap === "True") {
                        if (
                          "sphereclean admission fap cleaning"
                            .toLowerCase()
                            .includes(searchAtelier.toLowerCase())
                        ) {
                          return atelier;
                        }
                      }
                    }
                  }
                }
              }
            }
            return null;
          })
          .sort((a, b) => (a.name > b.name ? -1 : 1))
          .map((atelier) => {
            return (
              <Atelier
                key={atelier.node.id}
                name={atelier.node.name}
                CP={atelier.node.CP}
                rue={atelier.node.rue}
                Ville={atelier.node.Ville}
                EasyDiag={atelier.node.EasyDiag}
                EasyDiagDPF={atelier.node.EasyDiagDPF}
                Maxicleaner={atelier.node.Maxicleaner}
                NettoyageAdmissionFap={atelier.node.NettoyageAdmissionFap}
              />
            );
          })}
    </Row>
  );

  return (
    <Container fluid className={CarteStyles.Container}>
      {/* BENEFICES */}
      <Row className="pb-3 pt-3">
        <Col xs={3} className="align-self-center p-0">
          <div className={CarteStyles.LineBreak}></div>
        </Col>
        <Col xs={6} className="justify-content-center">
          <h1 className={CarteStyles.Title1}>{t("navbardetail.7")}</h1>
        </Col>
        <Col xs={3} className="align-self-center p-0">
          <div className={CarteStyles.LineBreak}></div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6}>
          <section className={CarteStyles.MapResponsive}>
            <iframe
              src="https://www.google.com/maps/d/u/0/embed?mid=1qeDt8qn_A-6snXERPisQwsRHucJ3ayga&z=6"
              frameBorder="0"
              allowFullScreen
              scrolling="no"
              aria-hidden="false"
              title="GoogleMapAtelier"
            />
          </section>
        </Col>
        <Col xs={12} lg={6}>
          <section className={CarteStyles.MapResponsive}>
            <Row>
              <Col
                xs={{ span: 10, offset: 1 }}
                className={CarteStyles.MapSearch}
              >
                <div className={CarteStyles.MapSearchInput}>
                  <input
                    type="text"
                    placeholder="Trouver un atelier... (Nom, Rue, Code Postal, Ville)"
                    onChange={(Event) => {
                      setSearchAtelier(Event.target.value);
                    }}
                  />
                </div>
              </Col>
            </Row>
            <AtelierGrid ateliers={ateliers.edges} />
          </section>
        </Col>
      </Row>
    </Container>
  );
}
