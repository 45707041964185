import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/Layout";
import "bootstrap/dist/css/bootstrap.min.css";
import Introduction from "../components/layout/Introduction";
import Explications from "../components/layout/Explications";
import Benefices from "../components/layout/Benefices";
import CarteAtelier from "../components/layout/CarteAtelier";
import Seo from "../components/seo";

export default function Home({ data }) {
  const cestquoi = data.cestquoi.nodes;
  const commentpart1 = data.commentpart1.nodes;
  const commentpart2 = data.commentpart2.nodes;
  const benefices = data.benefices.nodes;
  const ateliers = data.ateliers;

  return (
    <Layout>
      <Seo />
      <section id="introduction">
        <Introduction projects={cestquoi} />
      </section>
      <section id="explications">
        <Explications commentpart1={commentpart1} commentpart2={commentpart2} />
      </section>
      <section id="benefices">
        <Benefices benefices={benefices} />
      </section>
      <section id="localisation">
        <CarteAtelier ateliers={ateliers} />
      </section>
      {/* <section className={styles.header}>
        <div> */}
      {/* <h2>Design</h2>
                  <h3>Develop & Deploy</h3>
                  <p>UX designer & web developer based in Manchester.</p> */}
      {/* <h2>{t("Home")}</h2>
          <Link className={styles.btn} to="/projects">
            My Portfolio Projects
          </Link>
        </div> */}
      {/* <GatsbyImage image={image} alt="Banner"/> */}
      {/* </section> */}
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    cestquoi: allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        frontmatter: { slug: { eq: "cest-quoi" }, lang: { eq: $language } }
      }
    ) {
      nodes {
        html
        frontmatter {
          lang
          title
          stack
          slug
          thumb {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        id
      }
    }
    commentpart1: allMarkdownRemark(
      sort: { fields: frontmatter___order, order: ASC }
      filter: {
        frontmatter: { slug: { eq: "commentpart1" }, lang: { eq: $language } }
      }
    ) {
      nodes {
        html
        frontmatter {
          lang
          title
          stack
          slug
          link
          order
          thumb {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        id
      }
    }
    commentpart2: allMarkdownRemark(
      sort: { fields: frontmatter___order, order: ASC }
      filter: {
        frontmatter: { slug: { eq: "commentpart2" }, lang: { eq: $language } }
      }
    ) {
      nodes {
        html
        frontmatter {
          lang
          title
          stack
          slug
          link
          order
          thumb {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        id
      }
    }
    benefices: allMarkdownRemark(
      sort: { fields: frontmatter___order, order: ASC }
      filter: {
        frontmatter: { slug: { eq: "benefices" }, lang: { eq: $language } }
      }
    ) {
      nodes {
        html
        frontmatter {
          lang
          title
          stack
          slug
          order
        }
        id
      }
    }
    ateliers: allAtelierMapJson {
      edges {
        node {
          id
          CP
          EasyDiag
          EasyDiagDPF
          Maxicleaner
          NettoyageAdmissionFap
          Ville
          name
          rue
        }
      }
    }
  }
`;
