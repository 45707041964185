import React from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { Row, Col, Container } from "react-bootstrap";
import * as ExpliStyles from "../../styles/explications.module.css";

export default function Explications(props) {
  const commentpart1 = props.commentpart1;
  const commentpart2 = props.commentpart2;
  const { t } = useTranslation();

  return (
    <Container fluid className={ExpliStyles.Container}>
      {/* COMMENT */}
      <Row className="pb-2 pt-2">
        <Col xs={3} className="align-self-center p-0">
          <div className={ExpliStyles.LineBreak}></div>
        </Col>
        <Col xs={6} className="justify-content-center">
          <h1 className={ExpliStyles.Title1}>{t("navbar.2")}</h1>
        </Col>
        <Col xs={3} className="align-self-center p-0">
          <div className={ExpliStyles.LineBreak}></div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={{ span: 6, offset: 1 }}>
          {commentpart1.map((project) => (
            <div key={project.id}>
              <Row>
                <div
                  className={ExpliStyles.HtmlDiv}
                  dangerouslySetInnerHTML={{ __html: project.html }}
                />
                <div className="d-flex flex-row-reverse mb-lg-5">
                  {project.frontmatter.link ? (
                    <Link
                      className={ExpliStyles.Button}
                      to={"/" + project.frontmatter.link}
                    >
                      {t("ecosysteme.btn")}
                    </Link>
                  ) : (
                    <></>
                  )}
                </div>
              </Row>
            </div>
          ))}
        </Col>
        <Col
          xs={12}
          lg={{ span: 4, offset: 1 }}
          className="p-0 d-none d-lg-block"
        >
          <StaticImage
            className={ExpliStyles.GatsbyImage}
            src="../../images/icons/ecodiag2.png"
            alt="ecodiag-reduit"
            placeholder="BLURRED"
            formats={["AUTO", "WEBP"]}
            layout="constrained"
          />
        </Col>
        <Col
          xs={12}
          lg={{ span: 4, offset: 1 }}
          className="p-0 d-flex d-lg-none"
        >
          <StaticImage
            className={ExpliStyles.GatsbyImage}
            src="../../images/icons/ECODIAG.png"
            alt="ecodiag-large"
            placeholder="BLURRED"
            formats={["AUTO", "WEBP"]}
          />
        </Col>
      </Row>
      {commentpart2.map((project) =>
        project.frontmatter.order % 2 === 0 ? (
          <Row key={project.id}>
            <Col
              xs={{ span: 12, order: 2 }}
              lg={{ span: 4, offset: 1, order: 2 }}
              className="p-0"
            >
              <GatsbyImage
                className={ExpliStyles.GatsbyImage}
                image={getImage(
                  project.frontmatter.thumb.childImageSharp.gatsbyImageData
                )}
                alt="gaz"
              />
            </Col>
            <Col
              xs={{ span: 12, order: 1 }}
              lg={{ span: 6, offset: 1, order: 1 }}
              className={ExpliStyles.GreyCol}
            >
              <div
                className={ExpliStyles.HtmlDiv}
                dangerouslySetInnerHTML={{ __html: project.html }}
              />
              <div className="d-flex flex-row-reverse ">
                {project.frontmatter.link ? (
                  <Link
                    className={ExpliStyles.Button}
                    to={"/" + project.frontmatter.link}
                  >
                    {t("ecosysteme.btn")}
                  </Link>
                ) : (
                  <></>
                )}
              </div>
            </Col>
          </Row>
        ) : (
          <Row key={project.id} className={ExpliStyles.GreyRow}>
            <Col
              xs={{ span: 12, order: 2 }}
              lg={{ span: 4, order: 1 }}
              className="p-0"
            >
              <GatsbyImage
                className={ExpliStyles.GatsbyImage}
                image={getImage(
                  project.frontmatter.thumb.childImageSharp.gatsbyImageData
                )}
                alt="gaz"
              />
            </Col>
            <Col
              xs={{ span: 12, order: 1 }}
              lg={{ span: 6, offset: 1, order: 2 }}
              className={ExpliStyles.GreyCol}
            >
              <div
                className={ExpliStyles.HtmlDiv}
                dangerouslySetInnerHTML={{ __html: project.html }}
              />
              <div className="d-flex flex-row-reverse">
                {project.frontmatter.link ? (
                  <Link
                    className={ExpliStyles.Button}
                    to={"/" + project.frontmatter.link}
                  >
                    {t("ecosysteme.btn")}
                  </Link>
                ) : (
                  <></>
                )}
              </div>
            </Col>
          </Row>
        )
      )}
    </Container>
  );
}
